<template>
  <div class="fixed inset-0 flex flex-col justify-center items-center bg-black fixed inset-0">

  </div>

</template>

<script>
export default {
  name: "BlackBackground"
}
</script>

<style scoped>

</style>