<template>
  <div style="height: 100vh" class="flex justify-center items-center">
    <div class="flex flex-col">
      <input v-model="name" class="px-2 py-2 rounded-lg mb-2 focus:outline-none focus:ring focus:border-blue-300"
             placeholder="Name"
             type="text">
      <input v-model="password" class="px-2 py-2 rounded-lg mb-2 focus:outline-none focus:ring focus:border-blue-300"
             placeholder="Password" type="password">
      <button class="bg-blue-400 text-white shadow-lg mt-1 rounded-full py-1" @click="login(name, password)">Login
      </button>
      <div class="text-black fixed bottom-4 text-center bg-red-500 p-3 rounded-lg text-white" v-if="errorShow">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "Login",
  data() {
    return {
      vuetify: 'new Vuetify()',
      name: '',
      password: '',
      error: 'Wrong user or password',
      errorShow: false
    }
  },
  methods: {
    login(name, pass) {
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'login', {
        user: name,
        password: pass
      })
          .then((res) => {
            console.log(res)
            localStorage.setItem('token', res.data)
            this.$router.push('/admin')
          })
          .catch((err) => {
            console.log(err)
            this.errorShow = true
            setTimeout(() => {
              this.errorShow = false
            }, 5000)
          })
    }
  }
}
</script>

<style scoped>

</style>