<template>
  <div class="flex flex-col justify-start items-center py-4">
    <router-link class="z-50" to="/">
      <img class="w-1/6 mx-auto cursor-pointer z-50 mb-8" src="/logo_new.svg" alt="">
    </router-link>
    <BlackBackground></BlackBackground>
    <div class="z-50 container p-4" v-html="content"></div>
  </div>
</template>

<script>
import api from '@//util/api'
import BlackBackground from "@/components/Questions/BlackBackground";

export default {
  name: "Imprint",
  components: {BlackBackground},
  data() {
    return {
      content: ''
    }
  },
  mounted() {
    api.get('/site-imprint')
        .then(res => {
          this.content = res.data.data.attributes.Imprint
        })
  }
}
</script>

<style scoped>

</style>