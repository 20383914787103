<template>
  <div v-if="$route.name !== 'Imprint'"
       class="bg-transparent md:bg-transparent text-black md:text-primary text-center text-xs z-50">
    <div class="flex flex-col items-start">
      <div class="grid grid-cols-4  md:grid-cols-6 gap-8 md:gap-0 w-full md:px-4 md:py-2">
        <!-- Logo -->
        <div class="flex items-center md:col-span-4 hidden md:flex">
          <div class="text-left ">
            <p class="text-sm" v-html="text" v-show="text"></p>
          </div>
        </div>
        <!-- Links -->

        <div class="flex flex-col items-center justify-center col-span-1">
          <p class="mr-2 cursor-pointer text-sm mb-2" @click="$emit('contactClick')">Contact</p>
          <p class="mr-2 cursor-pointer text-sm mb-2" @click="openModalSocial();">Social</p>
          <!--          <router-link class="mr-2 " to="/imprint"><p class="text-sm">Imprint</p></router-link>-->
          <p class="mr-2 cursor-pointer text-sm mb-2" @click="openModalImprint();">Imprint</p>
        </div>

        <!-- Extra Info -->
        <div class="flex flex-col items-start col-span-3 md:col-span-1">

          <div :key="link.attributes.updatedAt" v-for="link in links">
            <p v-if="link.attributes.Active" class="mr-2 cursor-pointer text-sm mb-2">
              <a :href="link.attributes.Link">{{ link.attributes.Name }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--     Social Modal -->
    <!--    <transition name="fade">-->
    <!--      <modal @close="modalSocial = false; closeModalSocial();" v-if="modalSocial"-->
    <!--             class="">-->
    <!--        <div class="container text-white">-->
    <!--          <div class="flex flex-col my-8">-->
    <!--            <a class="mr-2 hover:text-orange" target="_blank" rel="noopener noreferrer" v-if="instagram"-->
    <!--               :href="instagram">Instagram</a>-->
    <!--            <a class="mr-2 hover:text-orange" target="_blank" rel="noopener noreferrer" v-if="vimeo"-->
    <!--               :href="vimeo">Vimeo</a>-->
    <!--            <a class="mr-2 hover:text-orange" target="_blank" rel="noopener noreferrer" v-if="linkedin"-->
    <!--               :href="linkedin">LinkedIn</a>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </modal>-->
    <!--    </transition>-->

    <!--    &lt;!&ndash;    Imprint Modal&ndash;&gt;-->
    <!--    <transition name="fade">-->
    <!--      <modal @close="modalImprint = false; closeModalImprint();"-->
    <!--             class=""-->
    <!--             v-if="modalImprint">-->
    <!--        <div class="">-->
    <!--          <div class="w-full text-left" v-html="content"></div>-->
    <!--        </div>-->
    <!--      </modal>-->
    <!--    </transition>-->

  </div>

</template>

<script>
import api from '@/util/api'

export default {
  name: "Footer",
  data() {
    return {
      modalImprint: false,
      instagram: null,
      vimeo: null,
      visit: 'https://goo.gl/maps/xzch1RQoGYVR3nEa9',
      tel: '0891234562',
      email: 'email@email.de',
      wtf: 'https://wtf.de',
      modalSocial: false,
      linkedin: null,
      text: null,
      badstudiolink: '',
      yesterday: '',
      bob: '',
      content: '',
      links: {}
    }
  },
  methods: {
    openModalSocial() {
      this.modalSocial = true;
      this.$emit('social-modal-opened', {instagram: this.instagram, vimeo: this.vimeo, linkedin: this.linkedin});
    },
    openModalImprint() {
      this.modalImprint = true;
      this.$emit('imprint-modal-opened', {content: this.content});
    }
  },
  mounted() {
    api.get(process.env.VUE_APP_API_ENDPOINT + '/info').then(res => {
      this.visit = res.data.data.attributes.Visit_Link
      this.tel = res.data.data.attributes.Tel_Number
      this.email = res.data.data.attributes.Email
      this.wtf = res.data.data.attributes.what_the_fuck
      this.text = res.data.data.attributes.Footer_Text
    })
    api.get('/site-imprint')
        .then(res => {
          this.content = res.data.data.attributes.Imprint
        })
    api.get('/extra-links')
        .then(res => {
          console.log(res)
          this.links = res.data.data
        }).catch(err => {
      console.log(err)
    })
  }
}
</script>

<style scoped>
.blurred {
  filter: blur(5px);
}
</style>
