<template>
  <transition name="fade">
    <div class="fixed inset-0 bg-black bg-opacity-80 shadow overflow-scroll z-50 md:z-50">
      <div class="flex justify-end">
        <img @click="$emit('close')"
             class="px-8 py-2 md:w-16 w-32 md:py-0 md:px-0 md:absolute md:top-4 md:right-4 top-4 right-4 cursor-pointer z-50"
             src="/cross.svg" alt="">
      </div>
      <div class="container mx-auto md:w-2/4 px-8 my-8 text-sm">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal"
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: all .4s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>