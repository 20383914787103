<template>
<div>
  <div class="z-10 fixed left-1/4 right-1/4 bottom-1 text-center bg-green-200 rounded-xl text-white p-2" v-if="$store.state.snackbar.success">
    SUCCESS
  </div>
</div>
</template>

<script>
export default {
name: "SnackbarSucess"
}
</script>

<style scoped>

</style>