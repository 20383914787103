<template>
  <div class="flex flex-col justify-center items-center bg-black" style="height: auto">
    <loading-spinner></loading-spinner>
    <snackbar-sucess></snackbar-sucess>
    <div class="container min-h-screen">
      <div class="w-full">
        <img class="w-1/4 mx-auto" src="/logo.png" alt="">
      </div>
      <div class="cursor-pointer flex my-8">
        <p @click="filmTab = true; corporateTab = false; campaignTab = false;" class="mr-8">Film</p>
        <p @click="corporateTab = true; filmTab = false; campaignTab = false;" class="mr-8">Corporate</p>
        <p @click="campaignTab = true; filmTab = false; corporateTab = false;" class="mr-8">Campaign</p>
      </div>

      <!-- Edit Modal -->
      <div style="height: 100VH;" v-if="editModal"
           class="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50 bg-black">
        <div class=" flex flex-col">
          <input
              class="px-2 py-2 rounded-lg mb-4 focus:outline-none focus:ring focus:border-blue-300 bg-black border border-primary"
              v-model="editModalInput.name" type="text">
          <textarea
              class="px-2 py-2 rounded-lg mb-4 focus:outline-none focus:ring focus:border-blue-300 bg-black border border-primary"
              v-model="editModalInput.description" type="text"></textarea>
          <select class="appearance-none focus:shadow-outline px-2 py-2 mb-4 rounded-lg bg-black border border-primary"
                  v-model="editModalInput.category">
            <option value="Film">Film</option>
            <option value="Corporate">Corporate</option>
            <option value="Campaign">Campaign</option>
          </select>
          <div class="flex items-center">
            <p>Menü</p>
            <input v-model="editModalInput.menu" class="ml-3 my-4" name="Menue" type="checkbox">
          </div>
          <div class="flex">
            <div class="mr-2">Video: </div>
            <input placeholder="Video" ref="file2" multiple type="file">
          </div>
          <div class="flex">
            <div class="mr-2">Thumbnail: </div>
            <input placeholder="Thumbnail" ref="file2thumb" multiple type="file">
          </div>

          <div class="my-4">
            <div v-if="modalDelete"
                 class="fixed left-0 right-0 top-0 bottom-0 flex justify-center items-center bg-black">
              <button class="border border-primary mr-2"
                      @click="kill(editModalInput.id); editModal = false; modalDelete = false">Yes
              </button>
              <button class="border border-primary" @click="modalDelete = false">
                Close
              </button>
            </div>
            <button class="border border-primary mr-2"
                    @click="updateFilm(); editModal = false">Save
            </button>
            <button @click="modalDelete = true"
                    class="border border-primary1 mr-2">
              Delete
            </button>
            <button class="border border-primary" style="z-index: 100"
                    @click="editModal = false">Close
            </button>
          </div>

        </div>
      </div>

      <!-- Film List -->
      <div v-if="filmTab" class="w-full">
        <div class="flex flex-col rounded-lg my-2" v-for="(film) in films" :key="film.key">
          <div @click="openModal(film)" class="p-4 cursor-pointer">
            <img class="w-24 float-left mr-8" :src="baseUrl + film.thumbnail" alt="">
            <div class="flex justify-between">
              <div>
                <h2 class="m-0 mr-4">Film: </h2>
                <p class=""> {{ film.name }}</p>
              </div>
              <button class="mr-2" v-if="editedId !== film.id">Edit</button>
            </div>
          </div>
          <div class="w-full mt-2 h-1 bg-white"></div>
        </div>
      </div>

      <!-- Corporate List -->
      <div v-if="corporateTab" class="w-full">
        <div class="flex flex-col rounded-lg my-2" v-for="(film) in corporate" :key="film.key">
          <div @click="openModal(film)" class="p-4 cursor-pointer">
            <img class="w-24 float-left mr-8" :src="baseUrl + film.thumbnail" alt="">
            <div class="flex justify-between">
              <div>
                <h2 class="m-0 mr-4">Film: </h2>
                <p class=""> {{ film.name }}</p>
              </div>
              <button class="mr-2" v-if="editedId !== film.id">Edit</button>
            </div>
          </div>
          <div class="w-full mt-2 h-1 bg-white"></div>
        </div>
      </div>

      <!-- Campaign List -->
      <div v-if="corporateTab" class="w-full">
        <div class="flex flex-col rounded-lg my-2" v-for="(film) in campaign" :key="film.key">
          <div @click="openModal(film)" class="p-4 cursor-pointer">
            <img class="w-24 float-left mr-8" :src="baseUrl + film.thumbnail" alt="">
            <div class="flex justify-between">
              <div>
                <h2 class="m-0 mr-4">Film: </h2>
                <p class=""> {{ film.name }}</p>
              </div>
              <button class="mr-2" v-if="editedId !== film.id">Edit</button>
            </div>
          </div>
          <div class="w-full mt-2 h-1 bg-white"></div>
        </div>
      </div>

      <!-- New Modal Button -->
      <div @click="modal = true" class="fixed bottom-1 rounded-lg cursor-pointer right-1 p-3 border border-primary">
        NEW
      </div>

      <!-- New Modal -->
      <transition name="modal">
        <div v-show="modal" style="z-index: 100; height: 100%"
             class="fixed top-0 left-0 bottom-0 right-0 bg-black flex justify-center items-center">
          <div @click="modal = false" class="fixed top-1 right-1 flex justify-center items-center">CLOSE</div>
          <div class="flex flex-col">
            <input v-model="name"
                   class="px-2 py-2 rounded-lg mb-4 focus:outline-none focus:ring border border-primary focus:border-blue-300 bg-black"
                   placeholder="Name" type="text">
            <textarea v-model="description"
                      class="px-2 py-2 rounded-lg mb-4 focus:outline-none focus:ring focus:border-blue-300 bg-black border border-primary"
                      placeholder="Description" type="text"></textarea>
            <select v-model="category"
                    class="appearance-none focus:shadow-outline px-2 py-2 mb-4 rounded-lg bg-black border border-primary">
              <option selected disabled>Kategorie</option>
              <option value="Film">Film</option>
              <option value="Corporate">Corporate</option>
              <option value="Campaign">Campaign</option>
            </select>
            <div class="flex items-center">
              <p>Menü</p>
              <input v-model="menu" class="ml-3 my-4 mb-4" name="Menue" type="checkbox">
            </div>
            <div class="flex items-center mb-4">
              <p>Video:</p>
              <input class="ml-4" ref="file" multiple type="file">
            </div>
            <div class="flex items-center mb-4">
              <p>Thumbnail:</p>
              <input class="ml-4" ref="thumb" multiple type="file">
            </div>
            <button @click="postFilm(); modal = false"
                    class="border border-primary">Save
            </button>
          </div>

        </div>
      </transition>
    </div>

  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import SnackbarSucess from "@/components/SnackbarSucess";

export default {
  name: "Admin",
  components: {SnackbarSucess, LoadingSpinner},
  data() {
    return {
      modal: false,
      filmTab: true,
      corporateTab: false,
      campaignTab: false,
      name: '',
      description: '',
      editedId: '',
      editModal: false,
      editModalInput: {},
      uploadFile: null,
      modalDelete: false,
      category: 'Kategorie',
      menu: false,
      baseUrl: process.env.VUE_APP_API_ENDPOINT
    }
  },
  methods: {
    edit(id) {
      this.editedId = id;
    },
    openModal(input) {
      this.editModal = true;
      this.editModalInput = input
    },
    postFilm() {
      const file = this.$refs.file.files
      const thumb = this.$refs.thumb.files
      let form = new FormData();
      form.append('file', file[0])
      form.append('name', this.name)
      form.append('description', this.description)
      form.append('category', this.category)
      form.append('menu', this.menu)
      form.append('thumb', thumb[0])
      this.$store.dispatch('POSTFILM', form)
    },
    updateFilm() {
      const file2 = this.$refs.file2.files
      const file2thumb = this.$refs.file2thumb.files
      var form = new FormData();
      form.append('file', file2[0])
      form.append('name', this.editModalInput.name)
      form.append('id', this.editModalInput.id)
      form.append('description', this.editModalInput.description)
      form.append('category', this.editModalInput.category)
      form.append('menu', this.editModalInput.menu)
      form.append('thumb', file2thumb[0])
      this.$store.dispatch('UPDATEFILM', form)
    },
    kill(el) {
      this.$store.dispatch('DELETE', el)
    }
  },
  mounted() {
    this.$store.dispatch('GETFILM')
  },
  watch: {
    changes(){

    }
  },
  computed: {
    films() {
      return this.$store.getters.category('Film')
    },
    corporate() {
      return this.$store.getters.category('Corporate')
    },
    campaign() {
      return this.$store.getters.category('Campaign')
    }
  }
}
</script>

<style scoped>

.modal-enter-active, .modal-leave-active {
  transition: all .5s ease;
}

.modal-enter, .modal-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

</style>