<template>
  <div @mouseenter="$emit('enter')" @mouseleave="$emit('leave')" class="grid md:grid-cols-5 w-full" v-if="employees">
    <div v-for="employee in employees" :key="employee.id">
      <div class="flex flex-col mx-4 mb-8">
        <div class="relative">
          <img v-if="employee.attributes.Images.data" class="w-full"
               :src="employee.attributes.Images.data[0].attributes.url || ''" alt="">
          <div class="absolute left-1 bottom-2 right-1 flex flex-col items-center justify-center text-white">
            <p class="text-white text-center" v-if="employee.attributes.Name">{{ employee.attributes.Name }}</p>
            <p class="text-white text-center" v-if="employee.attributes.Position">{{ employee.attributes.Position }}</p>
            <div v-if="employee.attributes.Beschreibung" class="" v-html="employee.attributes.Beschreibung"></div>
            <a v-if="employee.attributes.Mail" class="hover:text-white text-base py-2"
               :href="'mailto:' + employee.attributes.Mail">E-Mail</a>
            <div v-if="employee.attributes.Number">
              <a class="hover:text-white text-base"
                 :href="'tel:' + employee.attributes.Number">{{ employee.attributes.Number }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Employees",
  props: ['employees']
}
</script>

<style scoped>

</style>