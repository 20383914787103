<template>
  <div v-if="this.$store.state.loading === true" class="z-10 fixed top-0 left-0 right-0 bottom-0 w-100 h-100 bg-white flex justify-center items-center"><img style="" src="/spinner.gif" alt=""></div>
</template>

<script>
export default {
  name: "LoadingSpinner"
}
</script>

<style scoped>

</style>