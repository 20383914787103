<template>
  <div class="fixed inset-0 z-10 object-cover" id="bg">
    <transition name="fadeVideo">
      <img
          class="w-full h-full"
          :src="imgSrc"
          alt="">
    </transition>
  </div>
</template>

<script>
export default {
  name: "BackgroundVideo",
  props: ['imgSrc'],
  data() {
    return {}
  }
}
</script>

<style scoped>
.fadeVideo-enter-active, .fade-leave-active {
  transition: opacity .9s ease;
}

.fadeVideo-enter, .fadeVideo-leave-to {
  opacity: 0;
}


</style>