<template>
  <div class="h-1 w-full bg-white my-4"></div>
</template>

<script>
export default {
  name: "Divider"
}
</script>

<style scoped>

</style>