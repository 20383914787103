<template>
  <div style="height: 100vh;" class="relative flex flex-col justify-start items-center overflow-scroll">
    <router-link class="z-50 my-4" to="/">
      <img class="w-1/6 mx-auto cursor-pointer z-50 mb-8" src="/logo_orange.png" alt="">
    </router-link>
    <BlackBackground></BlackBackground>
    <div class="z-20 w-full px-4 md:w-2/3">
      <h1 v-if="request.requestName" @mouseenter="showRequest()" @mouseleave="hideRequest()" class="hover:text-white cursor-pointer mb-4"
          @click="request.modalRequest = !request.modalRequest">{{ request.requestName }}</h1>
      <transition name="slide">
        <div class="container" v-show="request.modalRequest">
          <div v-if="request.requestHeadline">{{ request.requestHeadline }}</div>
          <div v-html="request.requestContent"></div>
        </div>
      </transition>
      <Divider></Divider>
      <h1 @click="working.modalWorking = !working.modalWorking" @mouseenter="showEmployees" @mouseleave="hideRequest"
          class="hover:text-white cursor-pointer mb-4">{{employeesMenu || 'Who is working at this place'}}</h1>
      <transition name="slide">
        <div class="container" v-show="working.modalWorking">
          <Employees :employees="working.employees"></Employees>
        </div>
      </transition>
      <Divider></Divider>
      <h1 v-if="oneStop.oneStopName" @click="oneStop.modalOneStop = !oneStop.modalOneStop" @mouseenter="showOneStop" @mouseleave="hideRequest"
          class="hover:text-white cursor-pointer mb-4">{{ oneStop.oneStopName }}</h1>
      <transition name="slide">
        <div class="container" v-show="oneStop.modalOneStop">
          <div>{{ oneStop.oneStopHeadline }}</div>
          <div v-html="oneStop.oneStopContent"></div>
        </div>
      </transition>
      <Divider></Divider>
      <h1 @click="job.jobModal = !job.jobModal" @mouseenter="showJob" @mouseleave="hideRequest"
          class="hover:text-white cursor-pointer mb-4">{{job.jobName || 'Looking for a Job?'}}</h1>
      <transition name="slide">
        <div class="grid md:grid-cols-3 gap-4" v-if="job.jobs" v-show="job.jobModal">
          <div v-for="stelle in job.jobs" :key="stelle.Headline">
            <div>
              <h2>{{ stelle.attributes.Headline }}</h2>
              <div v-html="stelle.attributes.Description"></div>
            </div>
          </div>
        </div>
      </transition>
      <Divider></Divider>
      <h1 @click="reason.modalReason = !reason.modalReason" @mouseenter="showReason" @mouseleave="hideRequest"
          class="hover:text-white cursor-pointer mb-4">{{ reason.reasonName }}</h1>
      <transition name="slide">
        <div v-show="reason.modalReason">
          <div>{{ reason.reasonHeadline }}</div>
          <div v-html="reason.reasonContent"></div>
        </div>
      </transition>
      <Divider></Divider>
    </div>
    <BackgroundVideo :img-src="image"></BackgroundVideo>
  </div>
</template>

<script>
import BackgroundVideo from "@/components/BackgroundVideo";
import Divider from "@/components/Questions/Divider";
import BlackBackground from "@/components/Questions/BlackBackground";
import api from '@/util/api'
import Employees from "@/components/Questions/Employees";

export default {
  name: "Questions",
  components: {Employees, BlackBackground, Divider, BackgroundVideo},
  data() {
    return {
      image: null,
      oldImage: null,
      video: null,
      videoBoolean: false,
      employeesBg: null,
      modalEmployee: false,
      employeesMenu: null,
      request: {
        requestName: null,
        modalRequest: false,
        requestHeadline: null,
        requestContent: null,
        requestBg: null
      },
      working: {
        modalWorking: false,
        employees: [],
      },
      oneStop: {
        modalOneStop: false,
        oneStopHeadline: null,
        oneStopContent: null,
        oneStopBg: null,
        oneStopName: null
      },
      reason: {
        modalReason: false,
        reasonHeadline: null,
        reasonContent: null,
        reasonBg: null,
        reasonName: null
      },
      job: {
        jobs: null,
        jobModal: false,
        jobBg: null,
        jobName: null
      }
    }
  },
  async mounted() {
    await api.get('/site-request?populate=*').then(res => {
      this.request.requestName = res.data.data.attributes.Menu_Name
      this.request.requestHeadline = res.data.data.attributes.Headline
      this.request.requestContent = res.data.data.attributes.Text
      this.request.requestBg = res.data.data.attributes.Full_Size_Background.data.attributes.url
    })
    await api.get('/employees?populate=*').then(res => {
      this.working.employees = res.data.data
    })
    await api.get('/site-employee?populate=*').then(res => {
      this.employeesBg = res.data.data.attributes.BackgroundHover.data.attributes.url
      this.employeesMenu = res.data.data.attributes.Menu_Name
    })
    await api.get('/site-one-stop-atelier?populate=*').then(res => {
      this.oneStop.oneStopHeadline = res.data.data.attributes.Headline
      this.oneStop.oneStopName = res.data.data.attributes.Menu_Name
      this.oneStop.oneStopContent = res.data.data.attributes.Content
      this.oneStop.oneStopBg = res.data.data.attributes.Full_Size_Background.data.attributes.url
    })
    await api.get('/site-reason?populate=*').then(res => {
      this.reason.reasonHeadline = res.data.data.attributes.Headline
      this.reason.reasonName = res.data.data.attributes.Menu_Name
      this.reason.reasonContent = res.data.data.attributes.Content
      this.reason.reasonBg = res.data.data.attributes.Full_Size_Background.data.attributes.url
    })

    await api.get('/site-question?populate=*').then(res => {
      this.image = res.data.data.attributes.Full_Size_Background.data.attributes.url
      this.oldImage = res.data.data.attributes.Full_Size_Background.data.attributes.url
    })
    await api.get('/site-job?populate=*').then(res => {
      this.job.jobBg = res.data.data.attributes.Full_Size_Background.data.attributes.url
      this.job.jobName = res.data.data.attributes.Menu_Name
    })
    await api.get('/jobs').then(res => {
      console.log(res)
      this.job.jobs = res.data.data
    })

  },
  methods: {
    showRequest() {
      this.image = this.request.requestBg
    },
    hideRequest() {
      this.image = this.oldImage
    },
    showEmployees() {
      this.image = this.employeesBg
    },
    showOneStop() {
      this.image = this.oneStop.oneStopBg
    },
    showReason() {
      this.image = this.reason.reasonBg
    },
    showJob() {
      this.image = this.job.jobBg
    }
  }
}
</script>

<style>
.slide-enter-active, .fade-leave-active {
  transition: all .5s;
}

.slide-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
  transform: translateY(0);
}
</style>

